import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

function SideMenu() {
    const [showUserMenu, setShowUserMenu] = useState(false);
    const { userName } = useAuth();
    const [isVisible, setIsVisible] = useState(false);
    const [showPaymentsMenu, setShowPaymentsMenu] = useState(false);

    const { checkPermission } = useAuth();
    const navigate = useNavigate();

    const userInitials = () => {
        if (!userName)
            return "X";
        try {
            return userName.split(" ")[0][0] + userName.split(" ")[1][0];
        }
        catch {
            return "X";
        }
    }
    useEffect(() => {

    }, []);

    return (
        <nav>
            <div className="d-flex flex-column flex-shrink-0 p-1 text-white bg-secondary" style={{ width: '270px', height: '100vh' }}>
                <div className='navbar-collapse' id="sidebarCollapse">
                    <div>
                        <span className="d-flex flex-column align-items-center pb-1 my-1">
                            <img src="/assets/images/kig-e7Ti-vgY.png" className="mx-auto" alt="..." />
                            <span className="text-white" style={{ fontSize: '0.75rem' }}>KRAJOWA IZBA GOSPODARCZA</span>
                        </span>
                    </div>
                    <ul className="navbar-nav mb-auto">
                        {!checkPermission("Obsługa wysyłki") && (
                            <li className="nav-item mb-0">
                                <Link to="/application/list" className="btn btn-outline-primary w-100 ms-0 border-0 menu-item-white">
                                    <div className='me-auto fs-5'><svg className="bi me-1" width="16" height="16"><use xlinkHref="#table"></use></svg>Moje wnioski</div>
                                </Link>
                            </li>
                        )}
                        {!checkPermission("Obsługa wniosków") && !checkPermission("Obsługa wysyłki") && (
                            <li className='mb-0'>
                                <Link to="/application/edit/1/00000000-0000-0000-0000-000000000000" className="btn btn-primary ms-2 h2 fs-5" style={{ color: "#FFFFFF" }}>
                                    Utwórz wniosek
                                </Link>
                            </li>
                        )}
                        {!checkPermission("Obsługa wysyłki") && (
                            <li className="nav-item mb-0">
                            <Link to="/business/list" className="btn btn-outline-primary w-100 ms-0 border-0 menu-item-white">
                                <div className='me-auto fs-5'><svg className="bi me-1" width="16" height="16"><use xlinkHref="#building"></use></svg>Moje firmy</div>
                            </Link>
                        </li>
                        )}
                        <li className="nav-item mb-0">
                            <Link to="/certificate/internalcheck" className="btn btn-outline-primary w-100 ms-0 border-0 menu-item-white">
                                <div className='me-auto fs-5'><svg className="bi me-1" width="16" height="16"><use xlinkHref="#search"></use></svg>Sprawdź świadectwo</div>
                            </Link>
                        </li>
                        {checkPermission("Potwierdzanie płatności") && (
                            <li className="nav-item mb-0">
                                <div
                                    className="btn btn-outline-primary w-100 ms-0 border-0 menu-item-white"
                                    onClick={() => setShowPaymentsMenu(!showPaymentsMenu)}
                                >
                                    <div
                                        className="me-auto fs-5 d-flex justify-content-between align-items-center"
                                        onClick={() => {
                                            setShowPaymentsMenu(!showPaymentsMenu);
                                            navigate('/application/toPay');
                                        }}
                                    >
                                        <span>
                                            <svg className="bi me-1" width="16" height="16">
                                                <use xlinkHref="#grid"></use>
                                            </svg>
                                            Płatności
                                        </span>
                                        <i className={`bi ${showPaymentsMenu ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
                                    </div>
                                </div>
                                {showPaymentsMenu && (
                                    <ul className={`navbar-nav ms-3 fade ${showPaymentsMenu ? "fade-in" : ""}`}>
                                        <li className="nav-item mb-0">
                                            <Link
                                                to="/application/toPay"
                                                className="btn btn-outline-primary w-100 ms-0 border-0 menu-item-white"
                                            >
                                                Wnioski
                                            </Link>
                                        </li>
                                        <li className="nav-item mb-0">
                                            <Link
                                                to="/application/list"
                                                className="btn btn-outline-primary w-100 ms-0 border-0 menu-item-white"
                                            >
                                                Płatności odroczone
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        )}

                        {checkPermission("Obsługa wysyłki") && (
                            <li className="nav-item mb-0">
                                <Link to="/application/shipping" className="btn btn-outline-primary w-100 ms-0 border-0 menu-item-white">
                                    <div className='me-auto fs-5'><i className="bi bi-truck me-1" width="16" height="16" />Wysyłki</div>
                                </Link>
                            </li>
                        )}

                        {checkPermission("Zarządzanie użytkownikami") && (
                            <li className="nav-item">
                                <Link to="/user/list" className="btn btn-outline-primary w-100 ms-0 border-0 menu-item-white">
                                    <div className='me-auto fs-5'><svg className="bi me-1" width="16" height="16"><use xlinkHref="#people-circle"></use></svg>Użytkownicy</div>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="dropdown w-100">
                    {
                        <ul className={`ms-1 nav nav-pills flex-column fade ${showUserMenu ? "show" : ""}`} aria-labelledby="dropdownUser1">
                            <li className='nav-item mb-0'>
                                <Link to="/Login" className="btn btn-outline-primary w-100 ms-0 border-0 menu-item-white fs-5">
                                    <i className='bi-box-arrow-in-left me-1'></i>
                                    Zaloguj
                                </Link>
                            </li>
                            <li className='nav-item mb-0'>
                                <Link to="/user/profile" className="btn btn-outline-primary w-100 ms-0 border-0 menu-item-white fs-5">
                                    <i className='bi-person me-1'></i>
                                    Edytuj profil
                                </Link>
                            </li>
                            <li className='nav-item mb-0'>
                                <Link to="/Login" className="btn btn-outline-primary w-100 ms-0 border-0 menu-item-white fs-5">
                                    <i className='bi-box-arrow-right me-1'></i>
                                    Wyloguj
                                </Link>
                            </li>
                        </ul>
                    }
                    <div href="#" className="d-flex align-items-center text-decoration-none btn btn-outline-primary w-100 ms-0 border-0 menu-item-white" id="dropdownUser1" onClick={() => setShowUserMenu(!showUserMenu)}>
                        {/* <img src="/assets/images/user.svg" alt="" width="32" height="32" /> */}
                        <div className='rounded-circle border p-0 me-1 w1 h1 fs-6 menu-item-white' >
                            <div style={{ position: "relative", left: "2px", top: "3px" }}>{userInitials()}</div>
                        </div>
                        <div className='fs-5'>{userName}</div>
                        <div className='ms-auto'>
                            <i className={`bi ${showUserMenu ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
                        </div>
                    </div>

                    <div className='d-flex flex-row'>
                        <span className='text-white text-opacity-50 very-small ms-auto'>v. 20250213.01.01</span>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default SideMenu;
