import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StatusBadge from './StatusBadge';
import StatusTagSelect from './StatusTagSelect';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuth } from '../../context/AuthContext';
import { pl } from 'date-fns/locale';
import { use } from 'react';
registerLocale('pl', pl);

/**
 * DataTableApp component renders a data table with pagination, sorting, and filtering capabilities.
 *
 * @component
 * @param {Object[]} data - The data to be displayed in the table.
 * @param {number} itemsPerPage - Number of items to display per page.
 * @param {string[]} columnHeaders - Array of column headers for the table.
 *
 * @example
 * const data = [
 *   { NIP: '1234567890', Eksporter: 'Company A', Status: 'Active', 'Data utworzenia': '2023-01-01 12:00:00' },
 *   { NIP: '0987654321', Eksporter: 'Company B', Status: 'Inactive', 'Data utworzenia': '2023-02-01 12:00:00' }
 * ];
 * const itemsPerPage = 10;
 * const columnHeaders = ['NIP', 'Eksporter', 'Status', 'Data utworzenia', 'Action'];
 * 
 * <DataTableApp data={data} itemsPerPage={itemsPerPage} columnHeaders={columnHeaders} />
 *
 * @returns {JSX.Element} The rendered DataTableApp component.
 */
const DataTableApp = ({ data, itemsPerPage, columnHeaders, hiddenColumns = [], initialFilterStatuses = [], copyHandler, deleteHandler, colorMode = 'Client', lockStatuses = false }) => {
	const { userId } = useAuth();
	const location = useLocation();
	const pagePath = location.pathname;

	const [currentPage, setCurrentPage] = useState(1);
	const [sortColumn, setSortColumn] = useState('Data aktualizacji');
	const [sortOrder, setSortOrder] = useState('desc');
	const [sortedData, setSortedData] = useState([]);
	const [filterNIP, setFilterNIP] = useState('');
	const [filterExporterName, setFilterExporterName] = useState('');
	const [filterNumber, setFilterNumber] = useState('');
	//const [filterStatuses, setFilterStatuses] = useState([]);
	const [filterStatuses, setFilterStatuses] = useState(() => {
		const savedStatuses = localStorage.getItem(`filterStatuses_${userId}_${pagePath}`);
		return savedStatuses ? JSON.parse(savedStatuses) : [];
	});
	const [filterOperatorName, setFilterOperatorName] = useState('');
	const [filterDateFrom, setFilterDateFrom] = useState(null);
	const [filterDateTo, setFilterDateTo] = useState(null);
	const [filterLastUpdateFrom, setFilterLastUpdateFrom] = useState(null);
	const [filterLastUpdateTo, setFilterLastUpdateTo] = useState(null);
	const [showFilters, setShowFilters] = useState(false);
	let navigate = useNavigate();

	function editApplication(businessId) {
		navigate('/application/edit/1/' + businessId, { replace: true });
	}

	useEffect(() => {
		let filteredData = data;
		if (filterNIP) {
			filteredData = filteredData.filter(item => item.NIP.includes(filterNIP));
		}
		if (filterNumber) {
			filteredData = filteredData.filter(item => item.Numer.includes(filterNumber));
		}
		if (filterExporterName) {
			filteredData = filteredData.filter(item => item.Eksporter.toLowerCase().includes(filterExporterName.toLowerCase()));
		}
		if (filterStatuses.length) {
			filteredData = filteredData.filter(item => filterStatuses.includes(item.Status));
		}
		if (filterOperatorName) {
			filteredData = filteredData.filter(item => item["Przypisany do"].toLowerCase().includes(filterOperatorName.toLowerCase()));
		}
		if (filterDateFrom) {
			filteredData = filteredData.filter(item => new Date(item['Data utworzenia']) >= filterDateFrom);
		}
		if (filterDateTo) {
			filteredData = filteredData.filter(item => new Date(item['Data utworzenia']) <= filterDateTo.setHours(23, 59, 59, 999));
		}
		if (filterLastUpdateFrom) {
			filteredData = filteredData.filter(item => new Date(item['Data aktualizacji']) >= filterLastUpdateFrom);
		}
		if (filterLastUpdateTo) {
			filteredData = filteredData.filter(item => new Date(item['Data aktualizacji']) <= filterLastUpdateTo.setHours(23, 59, 59, 999));
		}
		if (sortColumn) {
			const sorted = [...filteredData].sort((a, b) => {
				if (a[sortColumn] < b[sortColumn]) {
					return sortOrder === 'asc' ? -1 : 1;
				}
				if (a[sortColumn] > b[sortColumn]) {
					return sortOrder === 'asc' ? 1 : -1;
				}
				return 0;
			});
			setSortedData(sorted);
		} else {
			setSortedData(filteredData);
		}
	}, [sortColumn, sortOrder, data, filterNIP, filterNumber, filterExporterName, filterStatuses, filterOperatorName, filterDateFrom, filterDateTo, filterLastUpdateFrom, filterLastUpdateTo]);

	useEffect(() => {
		setShowFilters(false);
		if (initialFilterStatuses.length) {
			console.log('Setting filter statuses: ', initialFilterStatuses);
			setFilterStatuses(initialFilterStatuses);
			setShowFilters(true);
		}
		else {
			console.log('Initial filter statuses not set - using localStorage');
			const savedStatuses = localStorage.getItem(`filterStatuses_${userId}_${pagePath}`);
			setFilterStatuses(savedStatuses ? JSON.parse(savedStatuses) : []);
			//setFilterStatuses([]);
		}
	}, [initialFilterStatuses, userId, pagePath]);

	useEffect(() => {
		localStorage.setItem(`filterStatuses_${userId}_${pagePath}`, JSON.stringify(filterStatuses));
	}, [filterStatuses, userId, pagePath]);

	useEffect(() => {
		if (colorMode !== 'Client') {
			const thirtyDaysAgo = new Date();
			thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
			setFilterLastUpdateFrom(thirtyDaysAgo);
		}
	}, []);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const handleSort = (column) => {
		if (sortColumn === column) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		} else {
			setSortColumn(column);
			setSortOrder('asc');
		}
	};

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

	return (
		<div>
			{(colorMode !== 'Client') && (
				<div className="border-top pt-1 mb-1">
					<label htmlFor="filterStatus" className="form-label">Status</label>
					<StatusTagSelect onChange={setFilterStatuses} data={filterStatuses} locked={lockStatuses} />
				</div>
			)}
			{!showFilters ? (
				<div className="d-flex flex-row mb-1">
					<button className="btn btn-outline-primary ms-auto w11 h2" onClick={() => setShowFilters(true)}><i className='bi bi-funnel me-1' />Pokaż filtry</button>
				</div>
			) : (
				<div className='d-flex flex-column border-bottom mb-1'>
					<div className="d-flex flex-row mb-1">
						<button className="btn btn-outline-primary ms-auto w11 h2" onClick={() => setShowFilters(false)}><i className='bi bi-funnel me-1' />Ukryj filtry</button>
					</div>
					<div className="d-flex flex-row">
						<div className="d-flex flex-column w13">
							<label htmlFor="filterNIP" className="form-label">NIP</label>
							<input
								type="text"
								id="filterNIP"
								className="form-control h2"
								placeholder='Wpisz fragment NIP'
								value={filterNIP}
								onChange={(e) => setFilterNIP(e.target.value)}
							/>
						</div>
						<div className="d-flex flex-column ms-1 w23">
							<label htmlFor="filterExporterName" className="form-label">Nazwa eksportera</label>
							<input
								type="text"
								id="filterExporterName"
								className="form-control h2"
								placeholder='Wpisz fragment nazwy eksportera'
								value={filterExporterName}
								onChange={(e) => setFilterExporterName(e.target.value)}
							/>
						</div>
					</div>
					<div className="d-flex flex-row">
						<div className="d-flex flex-column w13">
							<label htmlFor="filterNumber" className="form-label">Numer wniosku</label>
							<input
								type="text"
								id="filterNumber"
								className="form-control h2"
								placeholder='Wpisz fragment numeru wniosku'
								value={filterNumber}
								onChange={(e) => setFilterNumber(e.target.value)}
							/>
						</div>
					</div>
					<div className='d-flex flex-row'>
						<div className="d-flex flex-column w13">
							<div className="d-flex flex-row">
								<label className="form-label">Data utworzenia</label>
							</div>
							<div className="d-flex flex-row">
								<DatePicker
									selected={filterDateFrom}
									onChange={date => setFilterDateFrom(date)}
									selectsStart
									startDate={filterDateFrom}
									endDate={filterDateTo}
									placeholderText="Od"
									locale="pl"
									dateFormat="yyyy-MM-dd"
									className="form-control rounded-end-0 border-end-0 w5 h2" />
								<div className="form-control rounded-0 border-start-0 border-end-0 w3 px-1 h2">
									<i className="bi bi-arrow-right"></i>
								</div>
								<DatePicker
									selected={filterDateTo}
									onChange={date => setFilterDateTo(date)}
									selectsEnd
									startDate={filterDateFrom}
									endDate={filterDateTo}
									placeholderText="Do"
									locale="pl"
									dateFormat="yyyy-MM-dd"
									className="form-control rounded-start-0 border-start-0 w5 h2" />
							</div>
						</div>
						<div className="d-flex flex-column ms-1 w23">
							<label htmlFor="filterOperatorName" className="form-label">Przypisany do</label>
							<input
								type="text"
								id="filterOperatorName"
								className="form-control h2"
								placeholder='Wpisz fragment nazwy operatora'
								value={filterOperatorName}
								onChange={(e) => setFilterOperatorName(e.target.value)}
							/>
						</div>
					</div>
					<div className='d-flex flex-row'>
						<div className="d-flex flex-column w13">
							<div className="d-flex flex-row">
								<label className="form-label">Data aktualizacji</label>
							</div>
							<div className="d-flex flex-row mb-1">
								<DatePicker
									selected={filterLastUpdateFrom}
									onChange={date => setFilterLastUpdateFrom(date)}
									selectsStart
									startDate={filterLastUpdateFrom}
									endDate={filterLastUpdateTo}
									placeholderText="Od"
									locale="pl"
									dateFormat="yyyy-MM-dd"
									className="form-control rounded-end-0 border-end-0 w5 h2" />
								<div className="form-control rounded-0 border-start-0 border-end-0 w3 px-1 h2">
									<i className="bi bi-arrow-right"></i>
								</div>
								<DatePicker
									selected={filterLastUpdateTo}
									onChange={date => setFilterLastUpdateTo(date)}
									selectsEnd
									startDate={filterLastUpdateFrom}
									endDate={filterLastUpdateTo}
									placeholderText="Do"
									locale="pl"
									dateFormat="yyyy-MM-dd"
									className="form-control rounded-start-0 border-start-0 w5 h2" />
							</div>
						</div>
					</div>
				</div>
			)}
			<table className="table">
				<thead>
					<tr>
						{columnHeaders.map((header, index) => (
							!hiddenColumns.includes(header) && (
								<th key={index} onClick={() => handleSort(header)} style={{ cursor: 'pointer' }}>
									{header !== "Action" ? header : ""}
									{sortColumn === header && (
										sortOrder === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
									)}
								</th>
							)))}
					</tr>
				</thead>
				<tbody>
					{currentItems.map((row, rowIndex) => (
						<tr key={rowIndex}>
							{Object.keys(row).map((key, columnIndex) => (
								!hiddenColumns.includes(columnHeaders[columnIndex]) && (
									<td key={columnIndex}>
										{columnHeaders[columnIndex] === "Action" ? (
											<div className='d-flex flex-row'>
												<button
													className="ms-auto btn btn-outline-primary h2 tooltip"
													onClick={() => editApplication(row[key])}
												>
													Otwórz
													<span className='tooltiptext'>Otworz / edytuj wniosek</span>
												</button>
												{(colorMode === 'Client') && (
													<button className="btn btn-outline-primary ms-1 h2 tooltip" onClick={() => copyHandler(row[key], row["Nazwa"])}>
														<span className='bi bi-copy fs-5'></span>
														<span className='tooltiptext'>Kopiuj wniosek</span>
													</button>
												)}
												{(colorMode === 'Client') && (row["Status"].toLowerCase() === "roboczy") && (
													<button className="btn btn-outline-primary ms-1 h2 tooltip" onClick={() => deleteHandler(row[key], userId)}>
														<span className='bi bi-trash fs-5'></span>
														<span className='tooltiptext'>Usuń wniosek</span>
													</button>
												)}											</div>
										) : (
											columnHeaders[columnIndex] === "Data utworzenia" ? (
												<div>
													{row[key].split(' ')[0]}<br />{row[key].split(' ')[1]}
												</div>
											) : (
												columnHeaders[columnIndex] === "Status" ? (
													<StatusBadge text={row[key]} colorMode={colorMode} />
												) : (
													row[key]
												)
											)
										)}
									</td>
								)))}
						</tr>
					))}
				</tbody>
			</table>
			<nav>
				<ul className="pagination">
					{Array.from({ length: Math.ceil(sortedData.length / itemsPerPage) }, (_, i) => i + 1).map(pageNumber => (
						<li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
							<button onClick={() => paginate(pageNumber)} className="page-link">{pageNumber}</button>
						</li>
					))}
				</ul>
			</nav>
		</div>
	);
};

export default DataTableApp;