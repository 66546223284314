import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { ApiHost } from '../../utils/ApiConfig';
import LoadingSpinner from './LoadingSpinner';

function FileUploadComponent({ fileId, fileName, fileDescription, fileType, index, onChange, onRemove, readOnly = "false", showReadOnly = true, buttonLabel = "Dodaj plik" }) {
    const [file, setFile] = useState(null);
    const [selectedFileName, setFileName] = useState(fileName);
    const [selectedFileDescription, setFileDescription] = useState(fileDescription || "");
    const [uploading, setUploading] = useState(false);

    const fileInputRef = useRef(null);

    const handleUpload = async () => {
        if (!file) {
            alert('Proszę wybrać plik przed wysłaniem.');
            return;
        }

        const maxSizeInMB = 50;
        if (file.size > maxSizeInMB * 1024 * 1024) {
            alert(`Plik jest za duży. Maksymalny rozmiar to ${maxSizeInMB}MB.`);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            setUploading(true);
            const response = await axios.post(ApiHost + '/common/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setFileName(file.name);
            onChange(response.data.file_id, file.name, selectedFileDescription, fileType, index);
            fileInputRef.current.value = '';
        } catch (error) {
            console.error('Wystąpił błąd podczas przesyłania pliku:', error);
        } finally {
            setUploading(false);
        }
    };

    const getFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'pdf':
                return 'application/pdf';
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            case 'gif':
                return 'image/gif';
            case 'txt':
                return 'text/plain';
            case 'doc':
                return 'application/msword';
            case 'docx':
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'pptx':
                return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            case 'zip':
                return 'application/zip';
            case 'rar':
                return 'application/x-rar-compressed';
            default:
                return 'application/octet-stream'; // Domyślny typ pliku
        }
    };

    const downloadFile = async (fileId) => {
        try {
            const response = await fetch(ApiHost + `/common/download/${fileId}`);
            if (!response.ok) {
                throw new Error('Błąd podczas pobierania pliku');
            }
            const blob = await response.blob();
            const fileExtType = getFileType(fileName);
            const downloadUrl = window.URL.createObjectURL(new Blob([blob], { type: fileExtType }));
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Błąd podczas pobierania pliku:', error);
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
        }
    };

    const handleFileRemove = () => {
        setFile(null);
        setFileName('');
        setFileDescription('');
        onRemove();
    };

    const handleFileDescriptionChange = (e) => {
        setFileDescription(e.target.value);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        if (file) {
            handleUpload();
        }
    }, [file]);

    useEffect(() => {
        if (fileId && [false, "false"].includes(readOnly)) {
            onChange(fileId, selectedFileName, selectedFileDescription, fileType, index, false);
        }
    }, [selectedFileDescription, readOnly]);

    const shortenFileNameWithExtension = (fileName) => {
        let extension = fileName.split('.').pop();
        let name = fileName.substring(0, fileName.length - extension.length - 1);
        if (name.length > 30) {
            name = name.substring(0, 30) + '...';
        }
        return name + '.' + extension;
    }

    if ([true, "true"].includes(readOnly) && !fileId)
    { 
        if (showReadOnly)
            return (
                <div>
                    Brak pliku
                </div>
            );
        else
            return null;
    }

    if (uploading)
    {
        return (
            <div className='d-flex justify-content-between'>
                <div className='btn btn-outline-primary ms-0 w9 h2'>
                    <LoadingSpinner text='Ładowanie pliku' />
                </div>
            </div>
        );
    }

    return (
        <div className='flex-fill'>
            {(!fileId) ? (
                <div className='d-flex justify-content-between'>
                    <input type="file" onChange={handleFileChange} id="fileInput" ref={fileInputRef} style={{ display: "none" }} accept=".pdf, image/*" />
                    <div>
                        <button className='btn btn-outline-primary ms-0 w9 h2' onClick={handleButtonClick}>{buttonLabel}</button>
                    </div>

                    <div className='d-flex flex-row'>
                        <div className='flex-fill ms-1'>
                            <input type="text" className="form-control my-0 rounded-3 w26" maxLength="160" name="fileDescription" value={selectedFileDescription} onChange={handleFileDescriptionChange} placeholder="Opcjonalny opis pliku (max 160 znaków)" />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='d-flex justify-content-between'>
                    <div className='flex-fill tooltip'>
                        <label><a href="#" onClick={() => { downloadFile(fileId) }}>{shortenFileNameWithExtension(selectedFileName)}</a></label>
                        {(selectedFileName.length > 34) && (
                            <span className='tooltiptext tooltip-filename'>{selectedFileName}</span>
                        )}
                    </div>

                    <div className='d-flex flex-row'>
                        <div className='flex-fill ms-1'>
                            <input type="text" className="form-control rounded-3 w23" maxLength="160" name="fileDescription" value={selectedFileDescription} onChange={handleFileDescriptionChange} disabled={[true, "true"].includes(readOnly)} placeholder={[true, "true"].includes(readOnly) ? "" : "Opcjonalny opis pliku (max 160 znaków)"} />
                        </div>

                        {[false, "false"].includes(readOnly) && (
                            <div>
                                <button className='btn btn-outline-primary ms-1 p-0 w2 h2 fs-4' onClick={handleFileRemove}><span className='bi-trash'></span></button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default FileUploadComponent;
